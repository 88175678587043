<template>
  <tr
    :class="$store.getters.selectionMode ? 'table-row-selectionMode' : 'table-row'"
    @mouseover="checkboxVisible = true"
    @mouseleave="selected ? checkboxVisible = true : checkboxVisible = false"
  >

    <!-- Checkbox -->
    <td class="min-width-60 no-click">
      <div class="d-inline-flex">
        <transition name="fade">
          <b-form-checkbox
            v-show="checkboxVisible"
            :id="'checkbox-'+report.uuid"
            :name="'checkbox-'+report.uuid"
            value="true"
            v-model="selected"
            @change="checkboxChange"
            @click.stop
          >
          </b-form-checkbox>
        </transition>
      </div>
    </td>
    <!-- ID -->
    <td
      scope="row"
      class="min-width-120"
      @click="toggleExpanded(report.uuid)"
    >
      <Nature :nature="report.userNature" />
      {{ report.reportRef }}
    </td>
    <!-- Intervention type -->
    <!-- <td>
              <span v-if="report.interventionType != 'QUICK'"> {{ report.interventionType }}</span>

            </td> -->
    <!-- Priority / Result-->
    <td
      :v-show="$store.getters.columnVisibility.result == 'show'"
      @click="toggleExpanded(report.uuid)"
    >
      <Priority
        :priority="report.priority"
        v-if="report.priority != null"
      />
      <Quick
        :quickType="report.quickInteraction"
        v-if="report.quickInteraction != null"
      />
    </td>
    <!-- Status -->
    <td
      :v-show="$store.getters.columnVisibility.status == 'show'"
      @click="toggleExpanded(report.uuid)"
    >
      <Status
        :status="report.status"
        :target="'table'"
        class="margin-top-4"
      />
    </td>
    <!--  -->
    <td @click="toggleExpanded(report.uuid)"><mark
        class="wiRef txt-article-di"
        v-if="wiData.wiRef != null"
        v-b-tooltip.hover.bottom.html
        :title="`${wiData.wiPriority}<br>${wiData.wiDueDate}`"
        @click.stop="openModalWI(report.uuid)"
      >
        {{ wiData.wiRef }}
      </mark></td>
    <!--  -->
    <!-- <td> - - -</td> -->
    <!-- Building -->
    <td @click="toggleExpanded(report.uuid)">{{ this.localRef(report.buildingName, report.level, report.zone, report.serial, report.definition) }}</td>
    <!-- Level -->
    <!-- <td>{{ report.level }}</td> -->
    <!-- Zone -->
    <!-- <td>{{ report.zone }}</td> -->
    <!-- Serial -->
    <!-- <td>{{ report.serial }}</td> -->
    <!-- Definition -->
    <!-- <td>{{ report.definition }}</td> -->
    <!-- Programme -->
    <td @click="toggleExpanded(report.uuid)">
      <div
        v-b-tooltip.hover.v-secondary
        v-b-tooltip.hover.left.html="programComplete(report.familyName, report.activityName, report.specialtyName)"
        v-html="programOneLine(report.familyName, report.activityName, report.specialtyName)"
        class="td-program"
      ></div>
    </td>
    <!-- Description -->
    <td @click="toggleExpanded(report.uuid)">
      <div
        class="description-cell"
        v-b-tooltip.hover.v-secondary
        v-b-tooltip.hover.left.html="descriptionComplete({ description: report.description, remark: report.remarkLabels, standard: report.standard, correctiveAction: report.correctiveAction })"
        v-html="descriptionOneLine({ description: report.description, remark: report.remarkLabels, standard: report.standard, correctiveAction: report.correctiveAction })"
      ></div>
    </td>

    <!-- Intervention date -->
    <td @click="toggleExpanded(report.uuid)">{{ report.interventionDate }}</td>
    <!-- By -->
    <td @click="toggleExpanded(report.uuid)">{{ report.user.acronym4digits }}</td>
    <!-- Entity -->
    <td @click="toggleExpanded(report.uuid)">{{ report.entityName }}</td>

  </tr>
</template>
<script>
import Priority from './Priority.vue';
import { mapGetters } from 'vuex';
import Status from '@/components/Explore/SharedExploreComponents/Status.vue';
import Quick from './Quick.vue';
import Nature from './Nature.vue';
import { localRefMixin } from '../../../mixins/localRefMixin';

export default {
  name: 'table-row',
  props: ['report', 'toggleExpanded'],
  mixins: [localRefMixin],
  components: {
    Priority,
    Status,
    Quick,
    Nature,
  },
  data() {
    return {
      checkboxVisible: this.selected ? true : false,
    };
  },
  mounted() {
    this.$store.getters.selectedReports.includes(this.report.uuid)
      ? (this.checkboxVisible = Boolean(true))
      : (this.checkboxVisible = Boolean(false));
  },
  computed: {
    ...mapGetters({
      getWI: 'getWI',
    }),
    wiData() {
      return this.getWI(this.report.uuid);
    },
    selected: {
      set() {
        if (this.$store.getters.selectedReports.includes(this.report.uuid)) {
          return true;
        } else {
          return false;
        }
      },
      get() {
        if (this.$store.getters.selectedReports.includes(this.report.uuid)) {
          return true;
        } else {
          return false;
        }
      },
    },
  },
  watch: {
    selected: {
      deep: true,
      handler: function (value) {
        this.checkboxVisible = value;
      },
    },
  },
  methods: {
    openModalWI(reportUuid) {
      this.$store.commit('deleteSelection');

      this.$store.commit('selectReport', reportUuid);
      // Get selected report's data
      const reports = this.$store.state.reports;
      console.log(reports);
      const report = reports.reports.find(
        (report) => report.uuid === reportUuid
      );
      if (report) {
        this.$store.commit('selectReportData', report);
      }
      this.$bvModal.show('modal-wi');
    },
    checkboxChange() {
      console.log(`checkboxChange -> this.selected=${this.selected}`);
      if (this.selected == true) {
        console.log('unselectReport');
        this.$store.commit('unselectReport', this.report.uuid);
        this.$store.commit('unselectReportData', this.report.uuid);
      } else if (this.selected == false) {
        console.log('selectReport');
        this.$store.commit('selectReport', this.report.uuid);
        this.$store.commit('selectReportData', this.report);
      }
    },
    programOneLine(family, activity, specialty) {
      // console.log(family, activity, specialty);
      if (specialty != 'null') return specialty;
      if (specialty == 'null' && activity != 'null') return activity;
      if (activity == 'null' && family != 'null') return family;
      if (family == 'null') return '';
    },
    programComplete(family, activity, specialty) {
      let html;
      if (family != 'null') html = family;
      if (activity != 'null') html += '<br>' + activity;
      if (specialty != 'null') html += '<br>' + specialty;
      return html;
    },
    descriptionOneLine(obj) {
      // for (const [key, value] of Object.entries(obj)) {
      //   console.log(key, value);
      // }
      let html = '';
      if (obj.description == '') {
        html = obj.remark;
      } else {
        html = obj.description;
      }
      return html;
    },
    descriptionComplete(obj) {
      let html = '';
      for (const [key, value] of Object.entries(obj)) {
        if (value != '') {
          html +=
            '<b>' +
            this.$tc('report.' + key).toUpperCase() +
            '</b><br>\n' +
            value +
            '<br><br>\n';
        } else {
          html += '';
        }
      }
      return html;
    },
  },
};
</script>
<style scoped>

/* TODO: put these 2 followinf classes in css file, duplicate!!! */
.txt-article-di {
  color: #262626;
  line-height: 12px !important;
  font-size: 11px;
  font-weight: lighter !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0.1em;
  margin-top: 4px;
}
.wiRef {
  cursor: pointer;
}


.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.table-row {
  background-color: #fff;
}
.table-row-selectionMode {
  background-color: #ecf5ff;
}

.td-program {
  max-width: 300px; 
  overflow: hidden;
  white-space: nowrap; 
  text-overflow: ellipsis;
}
</style>
